.input-group {
    display:flex;
    margin-bottom: $size-m;
}

.input-group__item {
    margin-right: $size-m;
}
.add-point-form {
    max-width: 500px;
    margin: 0 auto;
}

.input-group__item__dropdown {
    width: 235px;
    padding: $size-base;
    border: 1px solid #cacccd;
    border-radius: 2px;
    height: 48px;
    font-size: $size-m;
    font-weight: 300;
}

.input-group__item__checkbox {
    @extend .input-group__item__dropdown;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.input-group__item__label {
    margin-left: 15px;
}

.input-group__item__button {
    @extend .input-group__item__dropdown;
    width: 100%;
    height: 55px;
    margin-bottom: $size-l;
    border: none;
    background-color: $chorely-blue;
    color: $chorely-white;
    font-weight: 600;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: darken($chorely-blue, 5%);
        transform: translate(1px, 1px);
    }
    
    &:active {
        transform: translate(2px, 2px);
    }
}