.add-point-modal__open {
    position: relative;
    top: -200;
    right: 200;
    padding: 0 15px 7px 15px;
    border: none;
    border-radius: 60%;
    outline: none;
    background-color: $chorely-blue;
    font-weight: 400;
    font-size: $size-xl;
    color: $chorely-white;
}  

.modal-hidden {
    display: none;
} 

.modal-visible {
    z-index: 1000;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    display: flex;
    margin: 15% auto;
    width: 600px;
    height: 400px;
    background-color: #F4F4F4;
}