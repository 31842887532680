* {
    box-sizing: border-box;  
}

html {
    font-size: 62.5%;
    margin: 0;
    padding: 0;
}

body {
    background-color: $chorely-light-gray;
    font-family: $font-family-main;
    font-size: 1.6rem;
}

.is-active {
    font-weight: bold;
}

.loading-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading { 
    height: 100px;
    width: 100px;
    background: transparent;
    border-radius: 50%;
    border: 2px solid $chorely-purple;
    border-top: 4px solid $chorely-yellow;
    animation: spinner1 600ms linear infinite;
} 

@keyframes spinner1 { 
    to { 
        transform: rotate(360deg);
    } 
} 