.current-week-stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 50vh;
}

.current-week-stats__item {
    width: 30%;
    border-radius: 4px;
    background-color: $chorely-white;
}

.current-week-stats__item__label {
    padding: $size-m;
    border-radius: 4px 4px 0 0;
    background-color: $chorely-yellow;
    color: $chorely-white;
    font-size: $size-m;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
}

.current-week-stats__item__value {
    padding: $size-m;
    border: 1px solid $chorely-yellow;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: $chorely-white;
    font-size: $size-xl;
    text-align: center;
}