@import url('https://fonts.googleapis.com/css?family=Kalam|Shadows+Into+Light&display=swap');

// FONT FAMILY
$font-family-script: 'Kalam', cursive;
$font-family-main: Helvetica, Arial, sans-serif;

// COLORS
$chorely-white: #FFFFFF;
$chorely-black: #222222;
$chorely-light-gray: #F4F4F4;
$chorely-blue: #33DACD;
$chorely-yellow: #FFB228;
$chorely-purple: #553B8B;

// SIZES/SPACING
$size-base: 1rem;
$size-s: 1.6rem;
$size-m: 2rem;
$size-l: 3.2rem;
$size-xl: 4.8rem;